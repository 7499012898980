<template>
  <div v-if="alert">
    <div>
      <b-row
        id="annoucement"
        class="message-alert justify-content-center d-none"
      >
        <b-col cols="12">
          <h2 class="tepa-h2 text-center">{{ title }}</h2>
        </b-col>
        <b-col col lg="9" md="10" sm="10" xs="11" style="margin-top: 10px">
          <prismic-rich-text
            class="text-center message-description"
            :field="info"
          />
        </b-col>
        <div class="close-btn" @click="SetAlert(false)">
          <img class="close-icon" :src="require(`@/assets/close-icon.svg`)" />
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      title: null,
      info: null,
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
    alert() {
      return this.$store.getters["cms/alert"];
    },
  },
  mounted() {
    this.getAnnoucement();
    const me = this;
    $(window).scroll(function () {
      me.SetAlert(false);
      me.$emit("update");
      $(window).off("scroll");
    });
    const interval = setInterval(() => {
      if ($(`#annoucement`).length > 0) {
        $(`#annoucement`)
          .addClass("animate__animated animate__fadeInUp")
          .removeClass("d-none");
        me.$emit("loaded");
        clearInterval(interval);
      }
    }, 500);
  },
  methods: {
    async getAnnoucement() {
      const params = {
        ref: this.ref,
        q: '[[at(document.tags, ["Annoucement"])]]',
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        this.SetAlert(data.show_annoucement);
        this.title = data.title[0].text;
        this.info = data.annoucement;
      }
    },
    async SetAlert(state) {
      await this.$store.dispatch("cms/setAlert", state);
    },
  },
};
</script>

<style scoped>
.message-alert {
  width: 100%;
  background: #fdebeb;
  opacity: 0.8;
  border-radius: 30px 30px 0px 0px;
  padding: 32px 0 63px;
  margin: 0;
}
.close-btn {
  position: absolute;
  right: 46px;
  cursor: pointer;
}
.message-description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 16px;
}
@media only screen and (max-width: 992px) {
  .message-description {
    font-size: 14px;
    line-height: 28px;
  }
  .tepa-h2 {
    font-size: 24px;
  }
  .close-btn {
    position: absolute;
    right: 40px;
    top: 35px;
    font-size: 20px;
    cursor: pointer;
  }
  .close-icon {
    height: 20px;
  }
}
</style>